.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard th,
.fc-theme-standard td {
    border-color: #e0e0e0;
}

.fc-theme-standard .fc-list-empty-cushion {
    padding: 2em;
    text-align: center;
    color: #888;
}

.fc .fc-toolbar.fc-header-toolbar {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.fc .fc-toolbar-title {
    color: #1D1D1D;
    font-size: 20px;
    font-family: 'Lato', sans-serif;
}

.fc .fc-col-header-cell-cushion {
    font-family: 'Lato', sans-serif;
    padding: 0 4px;
    line-height: 32px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.fc .fc-button {
    color: #fff;
    background-color: #1D1D1D;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
    height: 40px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    text-transform: capitalize;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    text-decoration: none;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    border: none;
}

.fc .fc-button:hover {
    text-decoration: none;
    background-color: #1D1D1D;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.fc .fc-button:active {
    background-color: #333;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.fc .fc-button:focus, .fc .fc-button-primary {
    box-shadow: none;
    outline: none;
}


.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #333;
    border: none;
    color: #fff;
}

.fc .fc-button-primary:not(:disabled):active {
    background-color: #333;
}

.fc .fc-button-primary:disabled {
    cursor: not-allowed;
}

.fc .fc-button-group > .fc-button {
    margin-left: 0;
    margin-right: 0;
}

.fc .fc-daygrid-event, .fc .fc-timegrid-event {
    background-color: transparent;
    border: none;
    color: #fff;
    border-radius: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
}

.fc .fc-daygrid-event:hover, .fc .fc-timegrid-event:hover,
.fc .fc-daygrid-event:focus, .fc .fc-timegrid-event:focus,
.fc .fc-daygrid-event:active, .fc .fc-timegrid-event:active {
    background-color: transparent;
}

.fc-event-selected, .fc-event:focus {
    box-shadow: none;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 4px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 4px;
}

.fc .fc-daygrid-event {
    margin-top: 5px;
}

.fc .fc-more-popover {
    background-color: #1D1D1D;
    border: none;
    color: #fff;
    max-width: 300px;
}

.fc .fc-daygrid-day-number, .fc .fc-timegrid-axis {
    color: #1D1D1D;
}

.fc .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 135px;
    background: #FAFAFA;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
    background-color: #e0e0e0;
}

.fc .fc-col-header-cell {
    background-color: #1D1D1D;
    border-bottom: 1px solid #e0e0e0;
    color: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}

.fc .fc-popover {
    background-color: #fff;
    border: none;
    color: #1D1D1D;
}

.fc .fc-popover-header {
    background-color: #1D1D1D;
    border-bottom: 1px solid #e0e0e0;
    color: #fff;
}

.fc .fc-popover-body {
    background-color: #fff;
    color: #1D1D1D;
}

.fc .fc-popover .fc-daygrid-event {
    background-color: #1D1D1D;
    color: #fff;
}

.fc .fc-popover .fc-daygrid-event:hover {
    background-color: #333;
}

.fc .fc-popover .fc-more {
    color: #fff;
}

.fc .fc-more-popover .fc-popover-body {
    padding: 4px;
}

.fc-col-header colgroup {
    display: none;
}

.fc-scrollgrid-sync-table colgroup {
    display: none;
}

.fc-timegrid-axis {
    display: none;
}

.fc-timegrid-divider {
    display: none;
}

.fc-timegrid {
    min-height: 700px;
}

.fc-scroller.fc-scroller-liquid {
    min-height: 700px;
}

.custom-event {
    background-color: #fff;
    color: #1D1D1D;
    border-radius: 0;
    padding: 5px;
}

.custom-event h4 {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
}

.custom-event p {
    margin: 0;
    font-size: 12px;
}

.calendar-container {
    min-height: 700px;
}

.fc-timeGridWeek-view .fc-daygrid-body .fc-scrollgrid-sync-table {
    min-height: 200px;
}


@media all and (min-width: 1600px) {
    .fc .fc-more-popover .fc-popover-body {
        min-width: 260px;
    }
}
